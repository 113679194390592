import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useGetAllGrindorSlugs} from "../../api/grindor";

interface SelectSlugProps {
    onChange: (value: string | undefined) => void;
}


const SelectSlug = (props: SelectSlugProps) => {
    const {onChange} = props;
    const [slug, setSlug] = useState<string | undefined>()
    const {
        data: slugs,
        error:slugsError,
        loading: slugsLoading,
    } = useGetAllGrindorSlugs();

    useEffect(() => {
        setSlug(undefined);
    }, [slugsLoading])

    useEffect(() => {
        onChange(slug)
        // TODO: fix with useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])

    if (slugsError) {
        return <div>Error loading slug</div>;
    }
    return (
        <Select
            value={slug}
            disabled={slugsLoading}
            loading={slugsLoading}
            showSearch
            allowClear={true}
            style={{width: 500}}
            placeholder="Slug"
            onChange={setSlug}
            onClear={() => setSlug(undefined)}
        >
            {!slugsLoading && slugs?.slugs?.map((v) => (
                <Select.Option
                    key={v}
                    value={v}
                >
                    {v}
                </Select.Option>
            ))}
        </Select>
    );
};
export default SelectSlug;
