import React from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { AuthContext } from "./auth.context";

export function useAuth() {
  const [user, loading] = useIdToken(auth);
  return { user, loading };
}

export function useUser() {
  const { user } = React.useContext(AuthContext);
  if (!user) return null;

  return user;
}

export function useAccessToken() {
  const user = useUser();
  return user?.getIdToken();
}

export function useAuthLoading() {
  return React.useContext(AuthContext).loading;
}
