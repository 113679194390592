import React from "react";
import { Layout } from "antd";
import { useLocalStorageState } from "ahooks";

import { Switch, Space } from "antd";
import "antd/dist/antd.css";
import "./App.css";

import { useTitle } from "ahooks";
import Lottie from "react-lottie";
import VoyantisLogoAnimation from "../assets/voyantis-logo-antimation.json";
import Universes from "./Universes";

const { Header, Content } = Layout;

function App({
  environment,
  token,
  userName,
}: {
  environment: string;
  token: string;
  userName: string;
}) {
  const [pride, setPride] = useLocalStorageState("isPrideMode");

  useTitle("Dr. Strange");

  return (
    <>
      <Layout className={pride ? "pride" : ""}>
        <Header
          className={"header"}
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "5px solid #003246",
          }}
        >
          <Space>
            <Lottie
              style={{ margin: 0 }}
              options={{
                loop: false,
                autoplay: true,
                animationData: VoyantisLogoAnimation,
                rendererSettings: {},
              }}
              height={65}
              width={100}
              isStopped={false}
              isPaused={false}
            />
            <h2 style={{ margin: 0 }}>Dr. Strange</h2>
            <h2 style={{ margin: 0, color: "red" }}>{environment}</h2>
          </Space>
          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              width: 200,
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <Space>
                {userName}
                <Space>
                  🌈
                  <Switch
                    checked={Boolean(pride)}
                    onChange={() => setPride((p: any) => (p ? "" : "true"))}
                  />
                </Space>
              </Space>
            </div>
          </div>
        </Header>
        <Content
          style={{ minHeight: "100vh", padding: "10px 5%", marginTop: "64px" }}
        >
          <Universes pride={Boolean(pride)} token={token} username={userName} />
        </Content>
      </Layout>
    </>
  );
}

export default App;
