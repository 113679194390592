import React from "react";
import {Spin} from "antd";
import {LineageDto, useGetLineage} from "../../../api/grindor";
import Tree, {RawNodeDatum} from "react-d3-tree";
import {Orientation, TreeLinkDatum} from "react-d3-tree/lib/types/types/common";
import {linkHorizontal, linkVertical} from 'd3-shape';


const lineageToTree = (lineage: LineageDto): RawNodeDatum => {
    const leaf = new Set();
    const map = new Map<string, RawNodeDatum>(lineage.nodes.map(o => [o.id, {name: o.id, children: []}]));
    for (let {source, target} of lineage.links) {
        const sourceNode = map.get(source)
        const targetNode = map.get(target)
        if (!sourceNode || !targetNode) {
            console.warn("nodes not found")
            continue;
        }
        leaf.add(target);
        sourceNode.children?.push(targetNode);
    }

    const trees: RawNodeDatum[] = Array.from(map.keys())
        .filter((id) => !leaf.has(id))
        .map(id => map.get(id)!)

    return {name: "__grindor", children: trees};
}

// @ts-ignore
const renderNode = ({nodeDatum, toggleNode}) => {
    const backgroundColor = nodeDatum.attributes?.backgroundColor || "white";
    const stroke = nodeDatum.attributes?.isSource ? "green" : "#f05";
    return (
        <g>
            {nodeDatum.name !== '__grindor' &&
            <>
                <rect
                    y="-1.7rem"
                    x={nodeDatum.name.length * -5}
                    width={nodeDatum.name.length * 10} height="3rem"
                    strokeWidth={"2"}
                    fill={backgroundColor}
                    onClick={toggleNode}
                    stroke={stroke}
                    rx={10}
                />
                <text
                    fill="black"
                    strokeWidth="0.8"
                    // x="50"
                    textAnchor="middle"
                    // y="-3.5rem"
                >
                    {nodeDatum.name}
                </text>
            </>}

        </g>
    );
};

const renderLink = (link: TreeLinkDatum, orientation: Orientation) => {
    const {source, target} = link;
    if (source.data.name === '__grindor') {
        return `M0,0L0,0`
    }
    return orientation === 'horizontal'
        ? linkHorizontal()({
            source: [source.y, source.x],
            target: [target.y, target.x],
        })
        : linkVertical()({
            source: [source.x, source.y],
            target: [target.x, target.y],
        });
}

interface GrindorLineageParams {
    customer: string;
    product: string;
    version: number;
}

export const GrindorLineage: React.FC<GrindorLineageParams> = (
    {
        customer,
        product,
        version,
    }) => {
    const {
        data: lineage,
        loading: lineageLoading,
        error: lineageError
    } = useGetLineage(customer, product, version)

    if (lineageLoading) {
        return <Spin/>
    }

    if (lineageError) {
        return <>'Error while loading Lineage'</>
    }

    if (!lineage?.lineage_graph) {
        return <>'Lineage could not be found'</>
    }

    const tree = lineageToTree(lineage.lineage_graph);
    const treesCount = tree.children!.length
    const maxIdSize = Math.max(...lineage.lineage_graph.nodes.map(n => n.id.length))
    return <div
        id="treeWrapper"
        style={{width: "100%", height: treesCount * 100}}
    >
        {lineage?.lineage_graph &&
        <Tree
            translate={{x: -50, y: (treesCount * 100) / 2}}
            renderCustomNodeElement={renderNode}
            // @ts-ignore
            pathFunc={renderLink}
            data={tree}
            nodeSize={{x: maxIdSize * 15, y: 100}}
            zoom={0.8}
            zoomable={false}
        />
        }
    </div>

}