import React from "react";
import {Tag, Tooltip, Badge} from "antd";
import "./IngestionStatus.css";

const badgeStyle: React.CSSProperties = {
    width: 14,
    textAlign: "center"
};

const Status = ({
                    name,
                    isRunning,
                    enabled,
                    errorLevel,
                    errorMessage,
                    verbose,
                }: {
    name?: string;
    isRunning: boolean;
    enabled: boolean;
    errorLevel?: string;
    errorMessage?: string;
    verbose?: boolean;
}) => {
    if (!enabled) {
        return (
            <Tooltip title={name}>
                {verbose ? <Tag>🚫 Disabled</Tag> : <Badge style={badgeStyle} status={"default"}/>}
            </Tooltip>
        );
    }
    if (isRunning) {
        return (
            <Tooltip title={name}>
                {verbose ? <Tag color={"blue"}>▶️ Running</Tag> : <Badge style={badgeStyle} status={"processing"}/>}
            </Tooltip>
        );
    }
    if (!errorLevel) {
        return (
            <Tooltip title={name}>
                {verbose ? <Tag color={"green"}>✅ Ok</Tag> : <Badge style={badgeStyle} status={"success"}/>}
            </Tooltip>
        );
    }
    const color = errorLevel === "warning" ? "gold" : "magenta";
    return (
        <Tooltip title={`${name && `${name}: `}${errorMessage}`} color={color}>
            {verbose ? <Tag color={color}>
                <>
                    {errorLevel === "warning"
                        ? `⚠️ Warning`
                        : `❌ Error`}
                </>
            </Tag> : <Badge style={badgeStyle} color={color}/>}

        </Tooltip>
    );
};

export default Status;
