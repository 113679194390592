import React, {useState} from "react";

export default function UtcTime() {
    const [time, setTime] = useState(new Date())
    React.useEffect(() => {
        const int = setInterval(() => {
            setTime(new Date())
        }, 1000)
        return () => {
            clearInterval(int)
        }
    }, [])
    return <div>
        UTC TIME: {time.toUTCString()}
    </div>
}