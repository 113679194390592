import {
  Button,
  Drawer,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import UniverseForm from "./UniverseForm";
import { DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useRequest } from "ahooks";
import Lottie from "react-lottie";
import VoyantisLaunchRocketAnimation from "../assets/voyantis-rocket-animation.json";
import { usePythonServerObject } from "../hooks/use-server-objects";
import { IUniverse } from "./IUniverse";
import dayjs from "dayjs";

const BASE_URI = process.env.REACT_APP_TBOARD_API_HOST;
const baseCols = [
  {
    title: "Created At",
    key: "createdAt",
    render: (universe: IUniverse) => {
      return (
        <Typography.Text>
          {universe.createdAt} ({dayjs(universe.createdAt).fromNow()})
        </Typography.Text>
      );
    },
  },
  {
    title: "Owner",
    key: "name",
    render: ({ createdBy }: IUniverse) => {
      return (
        <Typography.Text
          ellipsis={{
            tooltip: `${createdBy}`,
          }}
        >
          {createdBy}
        </Typography.Text>
      );
    },
  },
  {
    title: "Universe DB Name",
    key: "dbName",
    render: ({ dbName }: IUniverse) => {
      return (
        <Typography.Text
          ellipsis={{
            tooltip: dbName,
          }}
        >
          {dbName}
        </Typography.Text>
      );
    },
  },
  {
    title: "Enabled",
    key: "enabled",
    render: ( { enabled } : IUniverse) => {
      var text = enabled ? "enabled" : "disabled";
      return(
      <Typography.Text type={enabled ? "success" : "danger"}>{text}</Typography.Text>
      )
    },
  },
];
const Universes = ({
  pride,
  token,
  username,
}: {
  pride: boolean;
  token: string;
  username: string;
}) => {
  const authHeader = { Authorization: `Bearer ${token}` };
  const headers = { ...authHeader };
  const fetchConfig = { headers };
  const [showUniverseForm, setShowUniverseForm] = useState(false);
  const [isMutatingUniverse, setIsMutatingUniverse] = useState(false);

  const {
    serverObjects: universes,
    serverObjectsError: universesError,
    serverObjectsLoading: universesLoading,
    loadServerObjects: loadUniverses,
  } = usePythonServerObject<IUniverse>(
    () => "dr-strange/universes",
    fetchConfig
  );

  const { run: createUniverse } = useRequest(
    async (universe: Omit<IUniverse, "_id">) => {
      setIsMutatingUniverse(true);

      const res = await fetch(`${BASE_URI}/dr-strange/universes`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader,
        },
        body: JSON.stringify(universe),
      });
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        notification.error({ message: e.message });
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Universe Created!" });
          loadUniverses().catch();
          setShowUniverseForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  const { run: refreshViews } = useRequest(
    async () => {
      const res = await fetch(`${BASE_URI}/dr-strange/universes/refresh`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader,
        },
        body: JSON.stringify({"refresh" : true}),
      });
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        notification.error({ message: e.message });
      },
      onSuccess: (data: any) => {
        if (data.success) {
          notification.success({ message: "Views refreshed!" });
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  const { run: destroyUniverse } = useRequest(
    async (universe: IUniverse) => {
      setIsMutatingUniverse(true);

      const res = await fetch(
        `${BASE_URI}/dr-strange/universes/${universe.id}`,
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...authHeader,
          },
        }
      );
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        notification.error({ message: e.message });
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Universe Destroyed!" });
          loadUniverses().catch();
          setShowUniverseForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  const { run: changeUniverseEnableState } = useRequest(
    async (universe: IUniverse) => {
      setIsMutatingUniverse(true);

      const command = universe.enabled ? "disabled" : "enabled";
      const res = await fetch(
        `${BASE_URI}/dr-strange/universes/${universe.id}/set_state`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...authHeader,
          },
          body: JSON.stringify({state: command})
        }
      );
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        notification.error({ message: e.message });
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: 'Changed universe enabled state' });
          loadUniverses();
          setShowUniverseForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );
  if (universesError) {
    return <div>Error loading universe list</div>;
  }
  return (
    <>
      <Drawer
        destroyOnClose={true}
        width={"40%"}
        visible={showUniverseForm}
        onClose={() => {
          setShowUniverseForm(false);
        }}
      >
        <UniverseForm
          isMutating={isMutatingUniverse}
          onFinish={async (universe) => {
            await createUniverse({ ...universe, createdBy: username });
          }}
        />
      </Drawer>

      {universesLoading ? (
        <div
          style={{
            width: "100%",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Lottie
            style={{ margin: 0 }}
            options={{
              loop: true,
              autoplay: true,
              animationData: VoyantisLaunchRocketAnimation,
              rendererSettings: {},
            }}
            height={"10vw"}
            width={"10vw"}
            isStopped={false}
            isPaused={false}
          />
        </div>
      ) : (
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row"}}>
            <Button
              onClick={() => {
                setShowUniverseForm(true);
                setIsMutatingUniverse(false);
              }}
              type="primary"
            >
              New Universe
            </Button>
            <Button style={{ marginLeft: "10px" }}
              onClick={() => refreshViews()}
              type="primary"
            >
                Refresh joined db views
            </Button>
          </div>
          <Table
            pagination={false}
            scroll={{ x: "100%" }}
            dataSource={universes}
            columns={[
              ...baseCols,

              {
                title: "Actions",
                key: "destroy",
                render: (universe) => (
                  <Space>
                    <Popconfirm
                      title={
                        <Space direction={"vertical"}>
                          <div>Universe will be destroyed!</div>
                        </Space>
                      }
                      onConfirm={async () => {
                        await destroyUniverse(universe);
                      }}
                    >
                      <Tooltip title="Destroy">
                        <Button
                          loading={isMutatingUniverse}
                          danger={true}
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                      title={
                        universe.enabled ? (
                          <Space direction={"vertical"}>
                            <div>Disable the universe?</div>
                          </Space>
                        ) : (
                          <Space direction={"vertical"}>
                            <div>Enable the universe?</div>
                          </Space>
                        )
                      }
                      onConfirm={async () => {
                        await changeUniverseEnableState(universe);
                      }}
                    >
                      <Tooltip title={universe.enabled ? "Disable Universe" : "Enable Universe"}>
                        <Button type={universe.enabled ? "default" : "primary"}>
                          {universe.enabled ? "Disable" : "Enable"}
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
          />
        </Space>
      )}
    </>
  );
};

export default Universes;
