import React from "react";
import {Select} from "antd";
import {CustomerProductDto, useProducts} from "../../api/products";

export interface CustomerProduct {
    customer: string;
    product: string;
}

export interface SelectCustomerProductProps {
    onChange: (value: CustomerProduct | null) => void;
}

const SelectCustomerProduct: React.FC<SelectCustomerProductProps> = (props) => {
    const {onChange} = props;
    const {
        data: products,
        error: productsError,
        loading: productsLoading,
    } = useProducts()

    if (productsError) {
        return <div>Error loading products</div>;
    }

    return (
        <Select
            loading={productsLoading}
            showSearch
            allowClear={true}
            style={{width: 500}}
            placeholder="customer - product"
            optionFilterProp="children"
            onChange={(pid: string) => {
                const product = products!.find((p) => `${p.customer_id}-${p.product_id}` === pid);
                if (!product) {
                    return;
                }
                onChange({
                    customer: product.customer_id,
                    product: product.product_id
                });
            }}
            onClear={() => onChange(null)}
            filterOption={(input, option) => {
                return option && option.value
                    ? option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : true
            }

            }
        >
            {products?.map((p: CustomerProductDto) => `${p.customer_id}-${p.product_id}`)
                .sort()
                .map((pid: string) => (
                    <Select.Option
                        key={pid}
                        value={pid}
                    >
                        {pid}
                    </Select.Option>
                ))}
        </Select>
    );
};
export default SelectCustomerProduct;
