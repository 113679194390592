import React from "react";
import {Table} from "antd";
import {useGetStatus} from "../../../api/grindor";
import {useInterval} from "ahooks";


interface GrindorStatusParams {
    customer: string;
    product: string;
    version: number;
}

const columns = [
    {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
    },
    {
        title: 'Created At',
        dataIndex: 'time_created',
        key: 'time_created',
    },
    {
        title: 'Logs',
        dataIndex: 'dbt_job_id',
        key: 'dbt_job_id',
    },
];

export const GrindorStatus: React.FC<GrindorStatusParams> = (
    {
        customer,
        product,
        version,
    }) => {

    const {
        data: status,
        loading: statusLoading,
        refresh: statusRefresh,
    } = useGetStatus(customer, product, version);

    useInterval(() => {
        if (!statusLoading) {
            statusRefresh()
        }
    }, 30000, {immediate: true})

    const statuses = status?.statuses ?
        Object.entries(status?.statuses).map(([slug, items]) => (items[0])) :
        undefined;
    return <div>
        <Table dataSource={statuses}
               columns={columns} loading={statusLoading}/>
    </div>
}
