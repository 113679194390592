import { useRequest } from "ahooks";
import { useAccessToken } from "../auth/auth.hooks";

const API_V1 = `${process.env.REACT_APP_TBOARD_API_HOST}`;

export interface GetDataOptions {
  cacheKey?: string;
}

export function useGetData<ReturnType>(path: string, options?: GetDataOptions) {
  const token = useAccessToken();
  const { data, loading, error, refreshAsync, refresh } = useRequest<
    ReturnType,
    any
  >(
    async () => {
      const res = await fetch(`${API_V1}${path}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${await token}`,
        },
      });
      if (!res.ok) {
        const body = await res.text();
        throw new Error(body);
      }
      return await res.json();
    },
    {
      refreshDeps: [path],
      ...options,
    }
  );
  return { data, loading, error, refreshAsync, refresh };
}

export interface FindDataOptions<QueryType> {
  query?: QueryType;
}

export function useFindData<ReturnType, QueryType = object>(
  path: string,
  options?: FindDataOptions<QueryType>
) {
  if (options?.query) {
    path = `${path}?${new URLSearchParams(
      JSON.parse(JSON.stringify(options.query))
    )}`;
  }
  return useGetData<ReturnType>(`${path}`);
}

export interface MutateResultCallbacks<T = object> {
  onSuccess?: (result: T) => void;
  onError?: (e: Error) => void;
}

export function useMutateData<ReturnType, BodyType>(
  method: "POST" | "PUT" | "DELETE",
  path: string,
  options?: MutateResultCallbacks<ReturnType>
) {
  const token = useAccessToken();
  const { data, run, runAsync, loading, error } = useRequest<
    ReturnType,
    [BodyType?]
  >(
    async (body) => {
      const res = await fetch(`${API_V1}${path}`, {
        method,
        ...(body && { body: JSON.stringify(body) }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${await token}`,
        },
      });
      const contentType = res.headers.get("content-type");
      // TODO: consider api to always return json for 4XX
      const isJson =
        contentType && contentType.indexOf("application/json") > -1;
      if (!res.ok) {
        if (isJson) {
          throw await res.json();
        }
        throw new Error(await res.text());
      }

      if (isJson) {
        return await res.json();
      }
      return await res.text();
    },
    {
      manual: true,
      onSuccess: options?.onSuccess,
      onError: options?.onError,
    }
  );

  return {
    loading: loading,
    error,
    run,
    runAsync,
    data: data,
  };
}

export function usePostData<ReturnType, BodyType>(
  path: string,
  options?: MutateResultCallbacks<ReturnType>
) {
  return useMutateData<ReturnType, BodyType>("POST", path, options);
}

export function usePutData<ReturnType, BodyType>(
  path: string,
  options?: MutateResultCallbacks<ReturnType>
) {
  return useMutateData<ReturnType, BodyType>("PUT", path, options);
}

export function useDeleteData<ReturnType, BodyType = object>(
  path: string,
  options?: MutateResultCallbacks<ReturnType>
) {
  return useMutateData<ReturnType, BodyType>("DELETE", path, options);
}
