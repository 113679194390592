import React, {useState} from "react";
import {useRequest} from "ahooks";
import {Button, DatePicker, notification, Popconfirm, Select, Space, Switch} from "antd";
import SelectCustomerProduct from "./SelectCustomerProduct";
import SelectPipeline from "./SelectPipeline";
import mtz from "moment-timezone"

const formatTimestamp = (ts: number) => new Date(ts).toISOString();

function DeletePipelineData({token}: { token: string }) {
    const authHeader = {Authorization: `Bearer ${token}`};
    const headers = {
        ...authHeader,
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    const [customer, setCustomer] = useState<string>();
    const [product, setProduct] = useState<string>();
    const [selectedPipelines, setSelectedPipelines] = useState<string[] | null>(null);
    const [shouldDeleteAllData, setShouldDeleteAllData] = useState(false);
    const [dataStartTime, setDataStartTime] = useState<number | null>(
        null
    );


    const {
        data: pipelines,
        loading: pipelinesLoading,
        run: loadPipelines,
    } = useRequest<{ name: string, load: { eventTimestampColumnName: string } }[], any>(
        async ({customer, product}: { customer: string; product: string }) => {
            const res = await fetch(
                `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/pipelines`,
                {headers}
            );
            return res.json();
        },
        {manual: true}
    );

    const {
        data: ingestionTimes,
        loading: ingestionTimesLoading,
        run: loadIngestionTimes,
    } = useRequest<number[], any>(
        async ({
                   customer,
                   product,
                   pipelines,
               }: {
            customer: string;
            product: string;
            pipelines: string[];
        }) => {
            // the deletion by inhestion time is not important for this scenario.
            // so we are assuming that only one pipeline can be deleted with ingestion times selection.
            const res = await fetch(
                `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/pipelines/${pipelines[0]}/ingestion-times`,
                {headers}
            );
            return res.json();
        },
        {manual: true}
    );

    const {run: runDeletePipelineData} = useRequest<number[], any>(
        async (): Promise<any> => {
            const data_ts_server_format = dataStartTime! / 1000
            const deleteOnePipeline = async (pipeline: string) => {
                const res = await fetch(
                    `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/pipelines/${pipeline}/delete`,
                    {
                        headers,
                        method: "POST",
                        body: JSON.stringify({
                            delete_all: shouldDeleteAllData,
                            time_cursor: data_ts_server_format
                        }),
                    }
                );
                return res.json();
            };
            if (!selectedPipelines) {
                throw new Error("no pipelines to delete.")
            }
            for (const pipeline of selectedPipelines) {
                // we do it one after the other since we are not sure how the server
                // will react to many parallel requests.
                await deleteOnePipeline(pipeline);
            }

        },
        {
            manual: true,
            onSuccess: () => {
                if (!selectedPipelines) {
                    return
                }
                for (const pipeline of selectedPipelines) {
                    notification.success({message: `Pipeline ${pipeline} deletion started!`});
                }

            },
            onError: (e) => {
                console.log(e);
                notification.error({
                    message: `Error running delete ${e.message}`,
                });
            },
        }
    );

    let mustDeleteWithNormalizationTime = false;
    if (selectedPipelines && selectedPipelines.length > 0) {
        // for deletion by ingestion time, we don't support many pipelines.
        // so we take the first one, we disable the GUI later if there are many selected.
        mustDeleteWithNormalizationTime = !shouldDeleteAllData && pipelines?.find(p => p.name === selectedPipelines[0])!.load.eventTimestampColumnName === '_vy_ingestion_time'
    }
    let disableDeletionDueToManyPipelines = false;
    if (mustDeleteWithNormalizationTime && selectedPipelines && selectedPipelines.length > 1) {
        disableDeletionDueToManyPipelines = true;
    }

    return (
        <Space direction={"vertical"}>
            <div>
                <h3>Choose Product:</h3>
                <SelectCustomerProduct
                    onChange={(v) => {
                        if (!v) {
                            setProduct(undefined);
                            setCustomer(undefined);
                            return;
                        }
                        setProduct(v.product);
                        setCustomer(v.customer);
                        setSelectedPipelines(null)
                        loadPipelines({
                            customer: v.customer,
                            product: v.product,
                        });
                    }}
                />
                {customer && product && (
                    <SelectPipeline
                        value={selectedPipelines ?? undefined}
                        pipelines={pipelines?.map(p => p.name)}
                        pipelinesLoading={pipelinesLoading}
                        onChange={(_selectedPipelines: string[] | null) => {
                            if (_selectedPipelines?.length === 0) {
                                setSelectedPipelines(null);
                                return;
                            }
                            setSelectedPipelines(_selectedPipelines);
                            loadIngestionTimes({
                                customer: customer,
                                product: product,
                                pipelines: _selectedPipelines,
                            });
                        }}
                    />
                )}
            </div>
            <div>
                <h3>What should we delete?</h3>
                <Switch
                    unCheckedChildren="Delete some data" checkedChildren="Delete all data"
                    onChange={b => setShouldDeleteAllData(b)}
                    checked={shouldDeleteAllData}/>
            </div>
            {disableDeletionDueToManyPipelines ? <>
                You have chosen pipelines with conflicting configurations. Cannot bulk delete.
            </> : <>
                <div>
                    <h3>{shouldDeleteAllData ? "And set last ingested time to" : "Delete up to"}:</h3>
                    {mustDeleteWithNormalizationTime ?

                        <Select

                            disabled={!Boolean(selectedPipelines)}
                            value={dataStartTime ?? undefined}
                            style={{width: 500}}
                            placeholder={
                                ingestionTimesLoading
                                    ? "Loading options..."
                                    : "Choose Ingestion Time"
                            }
                            optionFilterProp="children"
                            onChange={(ts: number) => {
                                setDataStartTime(ts);
                            }}
                        >
                            <Select.Option value={0}>Dawn of time 🧙🏿</Select.Option>
                            {ingestionTimes?.map((ts) => (
                                <Select.Option key={ts} value={ts * 1000}>
                                    {formatTimestamp(ts * 1000)}
                                </Select.Option>
                            ))}
                        </Select>
                        :

                        <DatePicker disabled={!Boolean(selectedPipelines)} showTime={true} onChange={momentDate => {
                            if (!momentDate) {
                                return
                            }
                            // we assume the user selected his time knowing they are choosing UTC time
                            const utcTime = mtz.tz(momentDate.format("YYYY-MM-DD HH:mm:ss"), "utc")
                            setDataStartTime(Number(utcTime.toDate()))
                        }}/>
                    }


                </div>
                <div>
                    <Popconfirm
                        okText={"COOL"}
                        title={
                            <div>


                                {shouldDeleteAllData ? <p>
                                    ALL PIPELINE DATA WILL BE DELETED.
                                </p> : <p>
                                    We are going to delete all normalized data up to (excluding)
                                    {formatTimestamp(dataStartTime!)}</p>}
                                <div>
                                    Pipeline state will be set to:
                                    {formatTimestamp(dataStartTime!)}
                                </div>
                            </div>
                        }
                        onConfirm={async () => {
                            await runDeletePipelineData();
                        }}
                    >
                        <Button disabled={!Boolean(selectedPipelines)} type={"primary"} size="large">
                            Delete Pipeline Data
                        </Button>
                    </Popconfirm>
                </div>
            </>}

        </Space>
    );
}

export default DeletePipelineData;
