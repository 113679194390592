import { MutateResultCallbacks, useGetData, usePostData } from "./api";

export interface LineageDto {
  nodes: {
    id: string;
    config_schedule?: {
      cron: string;
    };
    config_trigger?: {
      trigger_on: {
        service_name: string;
        slug?: string | null;
        source_type?: string | null;
      }[];
    };
  }[];
  links: {
    source: string;
    target: string;
  }[];
}

interface GrindorLineageResponse {
  success: boolean;
  lineage_graph: LineageDto;
}

export const useGetLineage = (
  customer: string,
  product: string,
  version: number
) => {
  const path = `/${customer}/${product}/grindor/${version}/lineage`;
  return useGetData<GrindorLineageResponse>(path, { cacheKey: path });
};

interface GrindorSlugsResponse {
  success: boolean;
  slugs: string[];
}

interface GrindorWarehouseGroupsResponse {
	success: boolean;
	warehouse_groups: string[];
}

export const useGetGrindorWarehouseGroups = (
) => {
	const path = `/grindor/dbt_warehouse_groups`;
	return useGetData<GrindorWarehouseGroupsResponse>(path, { cacheKey: path });
};

export const useGetGrindorSlugs = (
  customer: string,
  product: string,
  version: number
) => {
  const path = `/${customer}/${product}/grindor/${version}/slugs`;
  return useGetData<GrindorSlugsResponse>(path, { cacheKey: path });
};

export const useGetAllGrindorSlugs = (
) => {
  const path = `/grindor/slugs`;
  return useGetData<GrindorSlugsResponse>(path, { cacheKey: path });
};

export const useGetGrindorSlugProducts = (
  slug: string
) => {
  const path = `/grindor/slug-customers/${slug}`;
  return useGetData<GrindorSlugsResponse>(path, { cacheKey: path });
};

export interface GrindorStatusDto {
  state: string;
  slug: string;
  time_created: string;
}

interface GrindorStatusResponse {
  success: boolean;
  statuses: { [slug: string]: GrindorStatusDto[] };
}

export const useGetStatus = (
  customer: string,
  product: string,
  version: number
) => {
  const path = `/${customer}/${product}/grindor/${version}/statuses`;
  return useGetData<GrindorStatusResponse>(path, { cacheKey: path });
};

export interface GrindorTriggerBody {
  slugs: string[];
  force_trigger: boolean;
  full_refresh: boolean;
  warehouse_group: string | null;
  ignore_lineage: boolean;
}

interface GrindorTriggerResponse {
  success: boolean;
}

export const useManualTrigger = (
  customer: string,
  product: string,
  version: number,
  options?: MutateResultCallbacks<GrindorTriggerResponse>
) => {
  const path = `/${customer}/${product}/grindor/${version}/trigger`;
  return usePostData<GrindorTriggerResponse, GrindorTriggerBody>(path, options);
};

export interface GrindorMultiSelectTriggerBody {
  slug: string;
  products: string[];
  warehouse_group: string | null;
  full_refresh: boolean;
  ignore_lineage: boolean;
}

export const useMultiSelectManualTrigger = (
  options?: MutateResultCallbacks<GrindorTriggerResponse>
) => {
  const path = `/grindor/trigger`;
  return usePostData<GrindorTriggerResponse, GrindorMultiSelectTriggerBody>(path, options);
};
