import { useGetData } from "./api";

export interface CustomerProductDto {
  customer_id: string;
  product_id: string;
}

export const useProducts = () => {
  const path = `/products`;
  return useGetData<CustomerProductDto[]>(path, { cacheKey: path });
};
