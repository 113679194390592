import React from 'react';
import { useAuth } from './auth.hooks';

interface AuthState {
    user: any | null;
    loading: boolean;

}

export const AuthContext = React.createContext<AuthState>({
    user: null,
    loading: true,
});

interface AuthProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProps) => {
    const auth = useAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};