import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useNormalizationVersions} from "../../api/normalization";

interface SelectNormalizationVersionProps {
    customer: string;
    product: string;
    onChange: (value: number | undefined) => void;
}


const SelectNormalizationVersion = (props: SelectNormalizationVersionProps) => {
    const {customer, product, onChange} = props;
    const [version, setVersion] = useState<number | undefined>()
    const {
        data: versions,
        error: versionsError,
        loading: versionsLoading,
    } = useNormalizationVersions(customer, product);

    useEffect(() => {
        setVersion(undefined);
    }, [customer, product, versionsLoading])

    useEffect(() => {
        onChange(version)
        // TODO: fix with useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version])

    if (versionsError) {
        return <div>Error loading version</div>;
    }
    return (
        <Select
            value={version}
            disabled={!customer || !product}
            loading={versionsLoading}
            showSearch
            allowClear={true}
            style={{width: 500}}
            placeholder="Versions"
            onChange={setVersion}
            onClear={() => setVersion(undefined)}
        >
            {!versionsLoading && versions?.data?.map((v) => (
                <Select.Option
                    key={v}
                    value={v}
                >
                    v{v}
                </Select.Option>
            ))}
        </Select>
    );
};
export default SelectNormalizationVersion;
