import React from "react";
import {Alert, Button, Input, Popover, Space, Switch, Table, Tag, Typography} from "antd";
import dayjs from "dayjs";
import "./IngestionStatus.css";
import Status from "./Status";
import {CustomerIngestionState, PipelineState} from "../IngestionStatus";
import relativeTime from "dayjs/plugin/relativeTime";
import useUrlState from "@ahooksjs/use-url-state";
import {Breakpoint} from "antd/es/_util/responsiveObserve";
import "./CustomersOverview.css";
import {InfoCircleOutlined, MinusOutlined} from "@ant-design/icons";

dayjs.extend(relativeTime);

const anySyncedPipelineEnabled = (state: CustomerIngestionState): boolean => {
    const sync_pipelines = state.pipelines.filter((p) =>
        state.synchronizer?.config.enabled_pipelines.includes(p.pipeline)
    );

    return sync_pipelines.filter(p => p.config.enabled).length > 0

}

const renderNormalizer = (state: CustomerIngestionState) => {
    const {normalizer} = state;
    if (!normalizer) {
        return <Typography.Text disabled>Normalizer doesn't exist.</Typography.Text>
    }

    if (!normalizer.config.enabled) {
        return <Typography.Text disabled>Normalizer is disabled</Typography.Text>
    }

    const times = state.pipelines
        .filter((p) => state.synchronizer?.config.enabled_pipelines.includes(p.pipeline))
        .map((pipeline) => pipeline.last_ingested_time)
        .sort();
    const minIngestionTime = dayjs(times[0]);
    const normalizerTime = dayjs(normalizer.last_ingested_time)

    const ingestionNormalizerDiffMinutes = minIngestionTime.diff(normalizerTime, "minutes")

    const isDelayed = ingestionNormalizerDiffMinutes > 1;
    let shouldShowError = isDelayed;

    if (isDelayed && normalizer.is_running) {
        shouldShowError = false
    }

    if (ingestionNormalizerDiffMinutes > 24 * 60) {
        shouldShowError = true
    }

    const normalizerTimeFormatted = dayjs(normalizer.last_ingested_time).format(
        "YYYY-MM-DD HH:mm:ss [UTC]"
    )
    const minIngestionTimeFormatted = minIngestionTime.format("YYYY-MM-DD HH:mm:ss [UTC]")
    const content = <>
        <div>
            <div style={{textAlign: "left", fontSize: ".7rem", color: "#ccc"}}>normalizer status</div>
            {renderNormalizerState(state)}
        </div>
        <div>
            <div style={{textAlign: "left", fontSize: ".7rem", color: "#ccc"}}>normalizer state</div>
            <Tag color={shouldShowError ? "warning" : "success"}>
                {normalizerTimeFormatted}
            </Tag>
        </div>
    </>
    return shouldShowError ? (
        <Popover title={"Warning reason"} content={<Typography.Text>
            Data is normalized up to {normalizerTimeFormatted}
            <br/>while the least synced pipeline time is {minIngestionTimeFormatted}
            <br/>
            <Tag
                color={"warning"}>({minIngestionTime.diff(dayjs(normalizer.last_ingested_time), "minutes")} minutes
                diff)</Tag>
            <br/><br/>
            Normalizer isn't running now, so this problem won't be fixed soon.

        </Typography.Text>}>
            {content}
        </Popover>
    ) : <Space direction={"vertical"}>
        {content}
    </Space>;
};

const renderSynchronizer = (state: CustomerIngestionState) => {
    const {pipelines, synchronizer: {config: {enabled_pipelines}}} = state;
    const syncedPipelines = pipelines.filter(p => enabled_pipelines.includes(p.pipeline));

    if (!anySyncedPipelineEnabled(state)) {
        return <Typography.Text disabled>All synced pipelines are disabled.</Typography.Text>
    }

    const pipelinesInfo: Record<string, { name: string, isDataStale: boolean, diffMinutes: number, diffMinutesWithStaleness: number, stalenessAllowedMinutes: number, lastIngestionTime: string }> = {}
    const nowDayJs = dayjs(new Date()) // let's say 10AM
    let isError = false;

    const enabledInternalPipelines = state.pipelines.filter(({config: {enabled}}) => enabled).filter(({pipeline}) => !(pipeline.startsWith("fb_ads") || pipeline.startsWith("google_ads")))

    for (const pipeline of enabledInternalPipelines) {
        if (!enabled_pipelines.includes(pipeline.pipeline)) {
            continue;
        }
        const lastDataTime = dayjs(pipeline.last_ingested_time) // let's say 9AM
        const stalenessAllowedMinutes = pipeline.config.monitoring_config?.data_staleness_threshold_minutes ?? 0; // let's say 30min
        const lastDataTimePlusAllowedStaleness = lastDataTime.add(stalenessAllowedMinutes, "minutes") // === 9:30AM
        const diffMinutesWithStaleness = lastDataTimePlusAllowedStaleness.diff(nowDayJs, "minutes") // should be minus if in the past
        const diffMinutes = lastDataTime.diff(nowDayJs, "minutes")
        const isDataStale = diffMinutesWithStaleness < 0 // diff is 30 min
        if (isDataStale) {
            isError = true
        }
        pipelinesInfo[pipeline.pipeline] = {
            name: pipeline.pipeline,
            isDataStale,
            lastIngestionTime: pipeline.last_ingested_time,
            stalenessAllowedMinutes,
            diffMinutesWithStaleness,
            diffMinutes
        }
    }

    const times = state.pipelines
        .filter((p) => state.synchronizer?.config.enabled_pipelines.includes(p.pipeline))
        .map((pipeline) => pipeline.last_ingested_time)
        .sort();

    const first = dayjs(times[0]).format("YYYY-MM-DD HH:mm:ss [UTC]");
    const last = dayjs(times[times.length - 1]).format(
        "YYYY-MM-DD HH:mm:ss [UTC]"
    );

    return <Space direction={"vertical"} align={'start'}>
        <div>
            <div style={{textAlign: "left", fontSize: ".7rem", color: "#ccc"}}>synced pipelines status</div>
            <div>{syncedPipelines.map((pipeline) => {
                return (
                    <Status
                        enabled={pipeline.config.enabled}
                        key={pipeline.pipeline}
                        name={pipeline.pipeline}
                        isRunning={pipeline.is_running}
                        errorLevel={pipeline.error_level}
                        errorMessage={pipeline.error_message}
                        verbose={false}
                    />
                );
            })}</div>
        </div>
        <div>
            <Popover
                placement={"bottom"}
                title={"pipeline times"}
                content={<Space direction={"vertical"}>
                    {isError && <Alert type={"warning"} message={<Typography.Text>
                        There is an error because after considering the allowed data staleness of each pipeline,
                        <br/>
                        their last ingestion time is not up-to-date.
                    </Typography.Text>}/>}
                    <Table
                        columns={[
                            {title: "name", dataIndex: "name"},
                            {
                                title: "data",
                                render: ({lastIngestionTime, isDataStale}) =>
                                    <Typography.Text>{lastIngestionTime}</Typography.Text>
                            },
                            {
                                title: "diff/allowed (min)",
                                render: ({
                                             isDataStale,
                                             diffMinutes,
                                             diffMinutesWithStaleness,
                                             stalenessAllowedMinutes
                                         }) => isDataStale ? <Tag color={"warning"}>

                                        {Math.abs(diffMinutesWithStaleness) + stalenessAllowedMinutes}/{stalenessAllowedMinutes}
                                    </Tag> :
                                    <Tag color={"success"}>  {Math.abs(diffMinutes)}/{stalenessAllowedMinutes}</Tag>
                            }
                        ]}
                        dataSource={Object.values(pipelinesInfo).sort((p1, p2) => p1.lastIngestionTime > p2.lastIngestionTime ? 1 : -1)}/>
                </Space>}

            >
                <div style={{textAlign: "left", fontSize: ".7rem", color: "#ccc"}}>synchronizer state (min - max)</div>
                <Tag style={{wordWrap: "break-word"}} color={isError ? "warning" : "default"}>{first}</Tag>
                {first !== last &&
                <Tag style={{wordWrap: "break-word"}} color={isError ? "warning" : "default"}>{last}</Tag>}
            </Popover>
        </div>
    </Space>
};

const renderNormalizerState = (state: CustomerIngestionState) => {
    const {normalizer} = state;
    if (!normalizer) {
        return <Status
            enabled={false}
            isRunning={false}
        />
    }
    if (!anySyncedPipelineEnabled(state)) {
        return <Status
            enabled={false}
            isRunning={false}
        />
    }
    return (
        <Status
            enabled={true}
            isRunning={normalizer.is_running}
            errorLevel={normalizer.error_level}
            errorMessage={normalizer.error_message}
        />
    );
};


const Empty = () => <Typography.Text style={{fontSize: ".8rem"}} disabled><MinusOutlined/></Typography.Text>

const PipelineBox = ({title, displayPipelines}: { title: string, displayPipelines: PipelineState[] }) => <div
    style={{display: "flex", gap: "1rem", flexDirection: "column", width: "33%"}}>
    <div style={{textAlign: "left", fontSize: ".7rem", color: "#ccc"}}>{title}</div>
    {displayPipelines.length === 0 && <Empty/>}
    <div>{displayPipelines.map((pipeline) => {
        return (
            <Status
                enabled={pipeline.config.enabled}
                key={pipeline.pipeline}
                name={pipeline.pipeline}
                isRunning={pipeline.is_running}
                errorLevel={pipeline.error_level}
                errorMessage={pipeline.error_message}
                verbose={false}
            />
        );
    })}</div>
</div>

const renderPipelines = (
    {
        pipelines,
    }: CustomerIngestionState,
) => {
    const isKeepersPipeline = (pipeline_state: PipelineState) => pipeline_state.pipeline.startsWith('fb_ads_') || pipeline_state.pipeline.startsWith('google_ads_')
    const isBiopsyPipeline = (pipeline_state: PipelineState) => pipeline_state.config.is_biopsy

    const keepersPipelines = pipelines.filter(p => isKeepersPipeline(p));
    const biopsyPipelines = pipelines.filter(p => isBiopsyPipeline(p));
    const customerPipelines = pipelines.filter(p => !isBiopsyPipeline(p) && !isKeepersPipeline(p));

    return (
        <div style={{display: "flex", flexDirection: "row", gap: "1rem"}}>
            <PipelineBox title={"customer"} displayPipelines={customerPipelines}/>
            <PipelineBox title={"biopsy"} displayPipelines={biopsyPipelines}/>
            <PipelineBox title={"keepers"} displayPipelines={keepersPipelines}/>
        </div>
    );
};

const sharedOnCell = (item: CustomerIngestionState) => {
    if (item.versionsCount > 1) {
        return {rowSpan: item.versionIndex === 0 ? item.versionsCount : 0};
    }
    return {};
};

const CustomersOverview = ({
                               customersIngestionState,
                               onSelectedCustomerProduct,
                           }: {
    customersIngestionState: CustomerIngestionState[];
    onSelectedCustomerProduct: (customerProduct: string | null) => void;
}) => {
    const data = customersIngestionState.map((customerIngestionState) => {
        return {
            key: `${customerIngestionState.customer}-${customerIngestionState.product}-${customerIngestionState.version}`,
            ...customerIngestionState,
        };
    });
    console.log(data)
    const [filters, setFilters] = useUrlState({
        customer: undefined,
        product: undefined,
        active: "Yes",
        s: undefined,
    });
    const getFilters = () => {
        return {
            product:
                !filters.product || Array.isArray(filters.product)
                    ? filters.product
                    : [filters.product],
            active:
                !filters.active || Array.isArray(filters.active)
                    ? filters.active
                    : [filters.active],
            customer:
                !filters.customer || Array.isArray(filters.customer)
                    ? filters.customer
                    : [filters.customer],
        };
    };

    const customers = Array.from(
        new Set(
            data.map((customersIngestionState) => customersIngestionState.customer)
        )
    );
    const products = Array.from(
        new Set(
            data.map((customersIngestionState) => customersIngestionState.product)
        )
    );

    const columns = ({
                         onDetailedView,
                     }: {
        onDetailedView: (customerIngestionState: CustomerIngestionState) => void;
    }) => [
        {
            title: "",
            key: "mobileView",
            render: (ingestionState: CustomerIngestionState) => {
                return (
                    <div className="mobile-view-cell">
                        <h1>
                            <div>
                                {ingestionState.customer === ingestionState.product ? (
                                    <>{ingestionState.customer} </>
                                ) : (
                                    <>
                                        {ingestionState.customer} {ingestionState.product}
                                    </>
                                )}
                            </div>
                        </h1>
                        <div className="normalizer-cell">
                            <div>
                                <h2>Version</h2>
                            </div>
                            <div><Tag>{ingestionState.version}</Tag></div>
                        </div>
                        <div className="normalizer-cell">
                            <div>
                                <h2>Normalizer</h2>
                            </div>
                            <div>{renderNormalizer(ingestionState)}</div>
                        </div>
                        <div className="pipelines-cell">
                            <div>
                                <h2>Synchronizer</h2>
                            </div>
                            <div>{renderSynchronizer(ingestionState)}</div>
                        </div>
                        <div className="pipelines-cell">
                            <div>
                                <h2>Pipelines</h2>
                            </div>
                            <div>{renderPipelines(ingestionState)}</div>
                        </div>
                        <div className={"more-info-cell"}>
                            <Button
                                onClick={() => {
                                    onDetailedView(ingestionState);
                                }}
                            >
                                <InfoCircleOutlined/>
                            </Button>
                        </div>
                    </div>
                );
            },
            responsive: ["xs"] as Breakpoint[],
            filterDropdown: () => (
                <div style={{padding: 8}}>
                    <div>
                        <Switch
                            checked={filters.active === "Yes"}
                            onChange={(v) =>
                                setFilters((oldFilters) => ({
                                    ...oldFilters,
                                    active: v ? "Yes" : "No",
                                }))
                            }
                        />{" "}
                        Show Active
                    </div>
                </div>
            ),
        },
        {
            title: "Customer",
            dataIndex: "customer",
            key: "customer",
            width: 100,
            filters: customers.map((customer) => ({
                text: customer,
                value: customer,
            })),
            filteredValue: getFilters().customer,
            responsive: ["sm"] as Breakpoint[],
            onCell: sharedOnCell
        },
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            width: 100,
            filters: products.map((product) => ({
                text: product,
                value: product,
            })),
            filteredValue: getFilters().product,
            responsive: ["sm"] as Breakpoint[],
            onCell: sharedOnCell
        },
        {
            title: "Active",
            key: "active",
            filters: [
                {
                    text: "Yes",
                    value: "Yes",
                },
                {
                    text: "No",
                    value: "No",
                },

            ],
            render: (state: CustomerIngestionState) => {
                return state.active ? <Tag color={"green"}>Yes</Tag> : <Tag color={"default"}>No</Tag>;
            },
            filteredValue: getFilters().active,
            responsive: ["sm"] as Breakpoint[],
            onCell: sharedOnCell
        },
        {
            title: "Pipelines Status",
            render: (customerIngestionState: CustomerIngestionState) =>
                renderPipelines(customerIngestionState),
            responsive: ["sm"] as Breakpoint[],
            onCell: sharedOnCell
        },
        {
            title: "Version",
            dataIndex: "version",
            key: "version",
            responsive: ["sm"] as Breakpoint[],
            render: (version: string) => <Space direction='vertical'><Tag>v{version}</Tag></Space>,
        },
        {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            responsive: ["sm"] as Breakpoint[],
            render: (tags: string[]) => <Space direction='vertical'>{tags?.map(tag => <Tag
                color={'blue'}>{tag}</Tag>)}</Space>,
        },
        {
            title: "Synchronizer Status",
            render: renderSynchronizer,
            responsive: ["sm"] as Breakpoint[],
        },
        {
            title: "Normalizer Status",
            render: renderNormalizer,
            responsive: ["sm"] as Breakpoint[],
        },
        {
            title: "Actions",
            key: "actions",
            render: (
                _text: string,
                customersIngestionState: CustomerIngestionState
            ) => {
                return (
                    <Space direction={"vertical"}>
                        <div>
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={`https://search-prod-u56sihsew64c3n546weljp3msa.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15d,to:now))&_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.product,negate:!f,params:(query:${customersIngestionState.product}),type:phrase),query:(match_phrase:(context.product:${customersIngestionState.product}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.customer,negate:!f,params:(query:${customersIngestionState.customer}),type:phrase),query:(match_phrase:(context.customer:${customersIngestionState.customer}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.app_name,negate:!f,params:!(nj-pipeline,nj-synchronizer,nj-normalizer,nj-scheduler),type:phrases,value:'nj-pipeline,%20nj-synchronizer,%20nj-normalizer,%20nj-scheduler'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(context.app_name:nj-pipeline)),(match_phrase:(context.app_name:nj-synchronizer)),(match_phrase:(context.app_name:nj-normalizer)),(match_phrase:(context.app_name:nj-scheduler))))))),index:'75449b20-9125-11eb-9da6-f373fd5db261',interval:auto,query:(language:kuery,query:''),sort:!())`}
                            >
                                Logs
                            </a>
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    onDetailedView(customersIngestionState);
                                }}
                            >
                                More
                            </Button>
                        </div>
                    </Space>
                );
            },
            responsive: ["sm"] as Breakpoint[],
        },
    ];
    return (
        <>
            <div>
                <Input.Search
                    enterButton={false}
                    onChange={(e) => {
                        setFilters((oldFilters) => ({
                            ...oldFilters,
                            s: e.target.value.toLocaleLowerCase(),
                        }))
                    }}
                    placeholder={"Search customer or product"} size="large"
                    value={filters.s}
                />
            </div>
            <Table
                scroll={{x: "max-content"}}
                className={"customers-overview-table"}
                onChange={(_, newFilters) => {
                    setFilters({...filters, ...newFilters});
                }}
                // @ts-ignore
                columns={columns({
                    onDetailedView: (customerIngestionState) => {
                        onSelectedCustomerProduct(
                            `${customerIngestionState.customer}-${customerIngestionState.product}-${customerIngestionState.version}`
                        );
                    },
                })}
                dataSource={data
                    .sort((a, b) => {
                        return a.customer < b.customer ? -1 : 1;
                    })
                    .filter((r) => {
                        if (!filters.s) {
                            return true;
                        }
                        return (
                            r.product.toLowerCase().includes(filters.s) ||
                            r.customer.toLowerCase().includes(filters.s)
                        );
                    })
                    .filter((record) => {
                        const filters = getFilters();
                        if (filters.customer) {
                            if (
                                filters.customer.every(
                                    (customer: string) => record.customer.indexOf(customer) === -1
                                )
                            ) {
                                return false;
                            }
                        }
                        if (filters.product) {
                            if (
                                filters.product.every(
                                    (product: string) => record.product.indexOf(product) === -1
                                )
                            ) {
                                return false;
                            }
                        }
                        if (filters.active) {
                            if (
                                !filters.active.includes("Yes") &&
                                record.active
                            ) {
                                return false;
                            }
                            if (
                                !filters.active.includes("No") &&
                                !record.active
                            ) {
                                return false;
                            }
                        }
                        return true;
                    })}
                pagination={false}
            /></>
    );
};
export default CustomersOverview;
