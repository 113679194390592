import { useGetData } from "./api";

interface NormalizationVersionsDto {
  success: boolean;
  data: number[];
}

export const useNormalizationVersions = (customer: string, product: string) => {
  const path = `/${customer}/${product}/normalization/versions`;
  return useGetData<NormalizationVersionsDto>(path, { cacheKey: path });
};
