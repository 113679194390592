import React from "react";
import {Select} from "antd";

const SelectPipeline = ({
                            pipelines,
                            pipelinesLoading,
                            onChange,
                            value
                        }: {
    value?: string[],
    pipelines: string[] | undefined,
    pipelinesLoading: boolean,
    onChange: (value: string[] | null) => void;
}) => {
    return (
        <Select
            loading={pipelinesLoading}
            showSearch
            allowClear={true}
            style={{width: 500}}
            mode="multiple"
            value={value}
            placeholder="Pipeline"
            optionFilterProp="children"
            onChange={(selectedPipelines: string[]) => {
                onChange(selectedPipelines);
            }}
            onClear={() => onChange(null)}
            filterOption={(input, option) =>
                option && option.children
                    ? option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : true
            }
        >
            {pipelines?.map((pipeline) => (
                <Select.Option key={pipeline} value={pipeline}>
                    {pipeline}
                </Select.Option>
            ))}
        </Select>
    );
};
export default SelectPipeline;
