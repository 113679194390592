import React, {useState} from "react";
import {useRequest} from "ahooks";
import {Button, notification, Popconfirm, Space} from "antd";
import SelectCustomerProduct from "./SelectCustomerProduct";
import SelectPipeline from "./SelectPipeline";

function ManualTriggerPipeline({
                                   token,
                                   userName,
                               }: {
    token: string;
    userName: string;
}) {
    const authHeader = {Authorization: `Bearer ${token}`};
    const headers = {...authHeader};
    const [customer, setCustomer] = useState<string>();
    const [product, setProduct] = useState<string>();
    const [selectedPipelines, setSelectedPipelines] = useState<string[] | null>(null);
    const {run: runTriggerPipeline} = useRequest<number[], any>(
        async (): Promise<any> => {
            const triggerPipeline = async (p: string) => {
                const res = await fetch(
                    `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/pipelines/${p}/trigger`,
                    {
                        headers,
                        method: "POST",
                    }
                );
                return res.json();
            };
            if (!selectedPipelines) {
                return false;
            }
            await Promise.all(selectedPipelines.map(triggerPipeline))
            return true;
        },
        {
            manual: true,
            onSuccess: () => {
                if (!selectedPipelines) {
                    return;
                }
                for (const pipeline of selectedPipelines) {
                    notification.success({message: `Pipeline ${pipeline} is triggered!`});
                }

            },
            onError: (e) => {
                console.log(e);
                notification.error({
                    message: `Error triggering pipeline ${e.message}`,
                });
            },
        }
    );

    const {
        data: pipelines,
        loading: pipelinesLoading,
        run: loadPipelines,
    } = useRequest<{ name: string }[], any>(
        async ({customer, product}: { customer: string; product: string }) => {
            const res = await fetch(
                `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/pipelines`,
                {headers}
            );
            return res.json();
        },
        {manual: true}
    );

    return (
        <Space direction={"vertical"}>
            <div>
                <h3>Choose Product:</h3>
                <SelectCustomerProduct
                    onChange={(v) => {
                        if (!v) {
                            setProduct(undefined);
                            setCustomer(undefined);
                            return;
                        }
                        setProduct(v.product);
                        setCustomer(v.customer);
                        loadPipelines({
                            customer: v.customer,
                            product: v.product,
                        });
                    }}
                />
                {customer && product && (
                    <SelectPipeline
                        pipelines={pipelines?.map(p => p.name)}
                        pipelinesLoading={pipelinesLoading}
                        onChange={(_selectedPipelines: string[] | null) => {
                            if (_selectedPipelines?.length === 0) {
                                setSelectedPipelines(null);
                                return;
                            }
                            setSelectedPipelines(_selectedPipelines);
                        }}
                    />
                )}
            </div>
            <div>
                <Popconfirm
                    okText={"COOL"}
                    title={<div>We are going to trigger {selectedPipelines?.join(",")}. cool?</div>}
                    onConfirm={async () => {
                        await runTriggerPipeline();
                    }}
                >
                    <Button disabled={!Boolean(selectedPipelines)} type={"primary"} size="large">
                        Trigger Pipelines
                    </Button>
                </Popconfirm>
            </div>
        </Space>
    );
}

export default ManualTriggerPipeline;
