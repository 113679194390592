import React, {useState} from "react";
import {useRequest} from "ahooks";
import {Spin, Drawer, Descriptions} from "antd";
import "./IngestionStatus/IngestionStatus.css";
import NormalizerStateView from "./IngestionStatus/NormalizerStateView";
import PipelineStatusTable from "./IngestionStatus/PipelineStatusTable";
import CustomersOverview from "./IngestionStatus/CustomersOverview";

export interface NormalizerState {
    current_ingestion_time?: string;
    customer: string;
    id: string;
    is_running: boolean;
    last_ingested_time: string;
    product: string;
    error_message?: string;
    error_level?: string;
    aws_batch_job_id?: string;
    config: { enabled: boolean }
}


export interface SynchronizerState {
    id: string;
    pipelines_ingestion_time: { [pipeline: string]: string; };
    config: { enabled_pipelines: string[] }
}

export interface PipelineState {
    product: string;
    last_ingested_time: string;
    pipeline: string;
    customer: string;
    is_running: boolean;
    id: string;
    aws_batch_job_id?: string | null;
    current_ingestion_time?: string;
    error_message?: string;
    error_level?: string;
    config: {
        enabled: boolean;
        schedule_cron: string;
        trigger_condition: string | null;
        trigger_range: string;
        is_biopsy: boolean;
        monitoring_config?: { data_staleness_threshold_minutes: number, time_since_ingestion_threshold_minutes: number }
    };
}

export interface NormalizationVersion {
    version: number;
    tags: string[];
    normalizer: NormalizerState;
    synchronizer: SynchronizerState;
}

export interface IngestionStatusResponse {
    customer: string;
    product: string;
    pipelines: [PipelineState];
    normalization_versions: NormalizationVersion[];
}

export interface CustomerIngestionState {
    customer: string;
    product: string;
    version: number;
    versionIndex: number;
    versionsCount: number;
    active: boolean;
    pipelines: [PipelineState];
    normalizer: NormalizerState;
    synchronizer: SynchronizerState;
}

function IngestionStatus({token}: { token: string }) {
    const authHeader = {Authorization: `Bearer ${token}`};
    const [selectedCustomerProduct, setSelectedCustomerProduct] = useState<string | null>(null);
    const headers = {
        ...authHeader,
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    const {data: ingestionStatusResponse, loading: ingestionStatusLoading} =
        useRequest<IngestionStatusResponse[], any>(
            async () => {
                const res = await fetch(
                    `${process.env.REACT_APP_TBOARD_API_HOST}/ingestion_state?customer=${selectedCustomerProduct?.split('-')[0]}&product=${selectedCustomerProduct?.split('-')[1]}`,
                    {headers}
                );
                return res.json();
            },
            {manual: false}
        );
    const customersIngestionState: CustomerIngestionState[] | undefined = ingestionStatusResponse?.map((status) => status.normalization_versions.map((config, index) => ({
        customer: status.customer,
        product: status.product,
        version: config.version,
        versionIndex: index,
        versionsCount: status.normalization_versions.length,
        active: status.pipelines.map(p => p.config.enabled).some(x => x)
            || status.normalization_versions.map(v => v.normalizer.config.enabled).some(x=>x),
        pipelines: status.pipelines,
        tags: config.tags,
        normalizer: config.normalizer,
        synchronizer: config.synchronizer,
    }))).flat();
    const selectedCustomerProductState = customersIngestionState?.find(
        (item) => `${item.customer}-${item.product}-${item.version}` === selectedCustomerProduct
    );
    return (
        <div>
            {ingestionStatusLoading || !customersIngestionState ? (
                <Spin/>
            ) : (
                <>
                    <Drawer
                        visible={Boolean(selectedCustomerProductState)}
                        width={'95dvw'}
                        height={'95dvh'}
                        destroyOnClose={true}
                        onClose={() => setSelectedCustomerProduct(null)}
                    >
                        {selectedCustomerProductState && (
                            <div>
                                <div style={{marginBottom: 24}}>
                                    <h4>Normalization Version</h4>
                                    <Descriptions bordered size={"small"} column={1}>
                                        <Descriptions.Item label="Version">
                                            {selectedCustomerProductState.version}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                                {selectedCustomerProductState.normalizer && (
                                    <div style={{marginBottom: 24}}>
                                        <h4>Normalizer</h4>
                                        <NormalizerStateView
                                            customer={selectedCustomerProductState.customer}
                                            product={selectedCustomerProductState.product}
                                            normalizerState={
                                                selectedCustomerProductState.normalizer
                                            }
                                            normalizationVersion={selectedCustomerProductState.version}
                                        />
                                    </div>
                                )}
                                <div>
                                    <h4>Pipelines</h4>
                                    <PipelineStatusTable
                                        customerIngestionState={selectedCustomerProductState}
                                    />
                                </div>
                            </div>
                        )}
                    </Drawer>
                    <CustomersOverview
                        customersIngestionState={customersIngestionState}
                        onSelectedCustomerProduct={(customerProduct: string | null) => {
                            setSelectedCustomerProduct(customerProduct);
                        }}
                    />
                </>
            )}
        </div>
    );
}

export default IngestionStatus;
