import { Button, Form, Input } from "antd";

import React from "react";
import { IUniverse } from "./IUniverse";
import LoadingAnimation from "../assets/36923-vedhase.json";
import Lottie from "react-lottie";

const UniverseForm: React.FC<{
  onFinish: (universe: IUniverse) => void;
  isMutating: boolean;
}> = ({ onFinish, isMutating }) => {
  const [form] = Form.useForm();
  return (
    <Form
      onFinish={async (formValue) => {
        onFinish({
          customer: formValue.customer,
          product: formValue.product,
          stagingName: formValue.stagingName,
          description: formValue.description,
        });
      }}
      form={form}
    >
      <Form.Item
        label="Customer"
        name="customer"
        rules={[{ required: true }]}
        help="Customer name"
      >
        <Input placeholder="Customer Name" />
      </Form.Item>
      <Form.Item
        label="Product"
        name="product"
        rules={[{ required: true }]}
        help="Product"
        labelCol={{}}
      >
        <Input placeholder="Product" />
      </Form.Item>
      <Form.Item
        label="Staging DB Name"
        name="stagingName"
        rules={[{ required: false }]}
        help="Staging Database name"
      >
        <Input placeholder="Staging Database name" />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true }]}
        help="Description"
      >
        <Input placeholder="Description" />
      </Form.Item>
      <Form.Item>
        <Button
          type={"primary"}
          onClick={() => form.submit()}
          loading={isMutating}
        >
          {"Create Universe"}
        </Button>
        {isMutating && (
          <Lottie
            style={{ margin: 0 }}
            options={{
              loop: true,
              autoplay: true,
              animationData: LoadingAnimation,
              rendererSettings: {},
            }}
            height={"100%"}
            width={"100%"}
            isStopped={false}
            isPaused={false}
          />
        )}
      </Form.Item>
    </Form>
  );
};
export default UniverseForm;
