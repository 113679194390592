import React from "react";
import {Space} from "antd";
import {Content} from "antd/es/layout/layout";
import {GrindorLineage} from "./GrindorLineage";
import {GrindorTrigger} from "./GrindorTrigger";
import {GrindorStatus} from "./GrindorStatus";
import {GrindorMultiSelectTrigger} from "./GrindorMultiSelectTrigger"

interface GrindorParams {
    customer: string;
    product: string;
    version: number;
}


export const Grindor: React.FC<GrindorParams> = (
    {
        customer,
        product,
        version,
    }) => {

    return <Content style={{}}>
        <Space direction={"vertical"} size={"large"}>
            <div>
                <h3>Manual Trigger:</h3>
                <GrindorTrigger customer={customer} product={product} version={version}/>
            </div>
            <div>
                <h3>Status:</h3>
                <GrindorStatus customer={customer} product={product} version={version}/>
            </div>
            <div style={{width: "100%", height: "100%"}}>
                <h3>Trigger Lineage:</h3>
                <GrindorLineage customer={customer} product={product} version={version}/>
            </div>
        </Space>
    </Content>
}

interface GrindorMultiSelectParams {
    slug: string;
    products: string[];
}

export const GrindorMultipleCustomers: React.FC<GrindorMultiSelectParams> = (
    {
        slug,
        products,
    }) => {

    return <Content style={{}}>
        <Space direction={"vertical"} size={"large"}>
            <div>
                <h3>Manual Trigger:</h3>
                <GrindorMultiSelectTrigger slug={slug} products={products}/>
            </div>
        </Space>
    </Content>
}
