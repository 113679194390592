import { useRequest } from "ahooks";

export function usePythonServerObject<ServerObjectType>(
  urlPathGetter: (...args: any[]) => string,
  headers: object
): {
  serverObjects: ServerObjectType[] | undefined;
  serverObjectsError: Error | undefined;
  serverObjectsLoading: boolean | undefined;
  loadServerObjects: (...args: any) => Promise<ServerObjectType[]>;
} {
  const {
    data: serverObjects,
    error: serverObjectsError,
    loading: serverObjectsLoading,
    run: loadServerObjects,
  } = useRequest<ServerObjectType[], any>(async (...args: any[]) => {
    const res = await fetch(
      `${process.env.REACT_APP_TBOARD_API_HOST}/${urlPathGetter(...args)}`,
      headers
    );
    return res.json();
  }, {});

  return {
    serverObjects,
    serverObjectsError,
    serverObjectsLoading,
    // @ts-ignore
    loadServerObjects,
  };
}
